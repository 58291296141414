<template>
  <div class="container-fluid">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">Manage Bookings</h1>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Booking Details</h6>
          </div>
          <div class="card-body">
            <form class="bookingData">
              <fieldset disabled>
                <div class="row">
                  <div class="col-lg-4" v-if="booking">
                    <label>First Name</label>
                    <input type="text" class="form-control" :placeholder="booking.firstName">
                  </div>
                  <div class="col-lg-4" v-if="booking">
                    <label>Last Name</label>
                    <input type="text" class="form-control" :placeholder="booking.lastName">
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4" v-if="booking">
                    <label>Email</label>
                    <input type="text" class="form-control" :placeholder="booking.email">
                  </div>
                  <div class="col-lg-4" v-if="booking">
                    <label>Phone Number</label>
                    <input type="text" class="form-control" :placeholder="booking.phoneNumber">
                  </div>
                </div>
                <div class="row">
                   <div class="col-lg-4" v-if="booking">
                    <label>Competition</label>
                    <input type="text" class="form-control" :placeholder="booking.competition.eventName">
                  </div>
                  <div class="col-lg-4" v-if="booking">
                    <label>Selected Days</label>
                    <textarea class="form-control" :value="formatSelectedDays()" rows="4" readonly></textarea>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4" v-if="booking">
                    <label>Services</label>
                    <input type="text" class="form-control" :placeholder="booking.services">
                  </div>
                  <div class="col-lg-4" v-if="booking">
                    <label>Services: Next Day</label>
                    <input type="text" class="form-control" :placeholder="booking.nextDay">
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4" v-if="booking">
                    <label>Services: Rhinestones</label>
                    <input type="text" class="form-control" :placeholder="booking.rhinestone">
                  </div>
                  <div class="col-lg-4" v-if="booking">
                    <label>Services: Eyelash Touchup</label>
                    <input type="text" class="form-control" :placeholder="booking.eyelash">
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4" v-if="booking">
                    <label>Dance Style</label>
                    <input type="text" class="form-control" :placeholder="booking.danceStyle">
                  </div>
                  <div class="col-lg-4" v-if="booking">
                    <label>Dance Level</label>
                    <input type="text" class="form-control" :placeholder="booking.danceLevel">
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4" v-if="booking">
                    <label>If Nadezda is not available...?</label>
                    <input type="text" class="form-control" :placeholder="booking.notAvailable">
                  </div>
                  <div class="col-lg-4" v-if="booking">
                    <label>Hair the night before?</label>
                    <input type="text" class="form-control" :placeholder="booking.hairAtNight">
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-8" v-if="booking">
                    <label>Message</label>
                    <textarea rows="4" class="form-control" :placeholder="booking.message" />
                  </div>
                </div>
              </fieldset>
            </form>
            <br />
            <hr>
            <h3>Assign Booking</h3>
            <form @submit.prevent="editBooking" v-if="booking">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="assignedUser">Assign User</label>
                    <select v-model="booking.assignedUser" class="form-control">
                      <option v-for="user in users" :key="user._id" :value="user._id">{{ user.firstName }} {{ user.lastName }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3">
                  <div class="form-group">
                    <label for="startDate">Start Date</label>
                    <input type="date" v-model="booking.startDate" class="form-control" />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="form-group">
                    <label for="startTime">Start Time</label>
                    <input type="time" v-model="booking.startTime" class="form-control" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3">
                  <div class="form-group">
                    <label for="endDate">End Date</label>
                    <input type="date" v-model="booking.endDate" class="form-control" />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="form-group">
                    <label for="endTime">End Time</label>
                    <input type="time" v-model="booking.endTime" class="form-control" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3">
                  <div class="form-group">
                    <label for="endDate">Dance Start Date</label>
                    <input type="date" v-model="booking.danceStartDate" class="form-control" />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="form-group">
                    <label for="endTime">Dance Start Time</label>
                    <input type="time" v-model="booking.danceStartTime" class="form-control" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="additionalNotes">Additional Notes</label>
                    <textarea v-model="booking.additionalNotes" class="form-control"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <router-link class="btn btn-primary btn-icon-split" to="/admin/calendar_bookings">
                      <span class="icon text-white-50">
                        <i class="fas fa-chevron-left"></i>
                      </span>
                      <span class="text">Back to Manage Bookings</span>
                    </router-link>
                  </div>
                  <div class="form-group">
                    <button @click="deleteBooking" type="button" class="btn btn-danger btn-icon-split">
                      <span class="icon text-white-50">
                        <i class="fas fa-trash"></i>
                      </span>
                      <span class="text">Delete Booking</span>
                    </button>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <div class="text-right">
                      <button type="submit" class="btn btn-success btn-icon-split">
                        <span class="icon text-white-50">
                          <i class="fas fa-check"></i>
                        </span>
                        <span class="text">Save</span>
                      </button>
                      <br />
                      <span v-if="contentSaved" class="badge badge-pill badge-success" style="font-size: 0.8rem;">Changes Saved</span>
                      <span v-if="contentError" class="badge badge-pill badge-danger" style="font-size: 0.8rem;">Error Saving</span>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="text-right">
                      <button @click="sendBookingEmail" type="button" class="btn btn-info btn-icon-split">
                        <span class="icon text-white-50">
                          <i class="fas fa-envelope"></i>
                        </span>
                        <span class="text">Send Booking Email</span>
                      </button>
                      <br />
                      <span v-if="contentEmailSent" class="badge badge-pill badge-success" style="font-size: 0.8rem;">Email Sent</span>
                      <span v-if="contentEmailError" class="badge badge-pill badge-danger" style="font-size: 0.8rem;">Email Sending Error</span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { fetchBooking, updateBookingDetails, deleteBookingRequest, sendBookingEmail } from '@/utils/booking';
import { fetchUsers } from '@/utils/users';

export default {
  name: 'AdminCalendarBookings',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      users: [],
      booking: null,
      contentSaved: false,
      contentError: false,
      contentEmailSent: false,
      contentEmailError: false,
    };
  },
  async created() {
    await this.loadBooking();
    await this.loadUsers();
  },
  methods: {
    async loadBooking() {
      // Fetch the booking details using the id prop
      try {
        this.booking = await fetchBooking(this.id);
      } catch (error) {
        console.error('Error fetching booking:', error);
      }
    },
    async loadUsers() {
      this.users = await fetchUsers(false);
    },
    async editBooking() {
      try {
        const status = await updateBookingDetails(this.booking);
        if(status.update === true) {
          this.contentSaved = true;
          this.contentError = false;
          
          setTimeout(() => {
            this.contentSaved = false;
          }, 5000);
        }
        else {
          this.contentSaved = false;
          this.contentError = true;
          
          setTimeout(() => {
            this.contentError = false;
          }, 5000);
        }
      } catch (error) {
        console.error('Error saving booking:', error);
      }
    },
    async deleteBooking() {
      if (confirm('Are you sure you want to delete this booking?')) {
        try {
          const status = await deleteBookingRequest(this.id);
          if (status.deleted) {
            alert('Booking deleted successfully');
            this.$router.push('/admin/calendar_bookings'); // Redirect to bookings list
          } else {
            console.log('Error deleting booking');
          }
        } catch (error) {
          console.error('Error deleting booking:', error);
        }
      }
    },
    async sendBookingEmail() {
      if (confirm('Are you sure you want to send an email to the customer?')) {
        try {
          const response = await sendBookingEmail(this.id);
        
          if(response.ok) {
            this.contentEmailError = false;
            this.contentEmailSent = true;
            
            setTimeout(() => {
              this.contentEmailSent = false;
            }, 5000);
          }
          else {
            this.contentSaved = false;
            this.contentEmailError = true;
            
            setTimeout(() => {
              this.contentEmailError = false;
            }, 5000);
          }
          
        } catch (error) {
          console.error('Error sending booking email:', error);
        }
      }
    },
    formatSelectedDays() {
      if (!this.booking || !this.booking.selectedEventDays) return '';
      return this.booking.selectedEventDays
        .map(dateString => {
          return moment(dateString).format('dddd (MM/DD)');
        })
        .join('\n');
    },
  }
};
</script>

<style scoped>
.bookingData .row+.row {
    margin-top: 1rem;
}
</style>