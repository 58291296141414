<template>
  <section class="hero d-flex align-items-center text-white text-center">
    <div class="container">
      <h1><span class="highlight">Book An Appointment</span></h1>
      <p>Please, fill out the form to book your appointment.<br />
        You will receive a booking confirmation within 24 - 48 hours.</p>
      <h5>For last minute inquiries, please text us at <span class="highlight">1(347)481-8056</span></h5>
    </div>
  </section>
  <section class="py-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          <div v-if="formSubmitted" class="success-message">
            <p>Thank you for booking an appointment with us! We will contact you with further details.</p>
            <button @click="resetForm" class="btn btn-warning btn-block">Book Another Appointment</button>
          </div>
          <div v-else>
            <form class="content-block contact-form p-4" id="book-form" @submit.prevent="submitForm">
							<h3>Appointment Information</h3>
							<div class="form-row">
								<div class="form-group col-md-6">
									<label for="competition" class="highlight">Select Dance Competition</label>
                  <select class="form-control contact-input" id="competition" v-model="form.competition" required>
                    <option disabled selected>---SELECT---</option>
                    <option v-for="event in events" :key="event._id" :value="event._id">{{ event.eventName }} ({{ formatDate(event.eventStartDate) }} - {{ formatDate(event.eventEndDate) }})</option>
                  </select>
								</div>
								<div class="form-group col-md-6">
									<label for="services" class="highlight">Services</label>
									<select class="form-control contact-input" id="services" v-model="form.services" required>
										<option disabled selected>---WOMEN---</option>
										<option value="women-hair">Hairstyle</option>
										<option value="women-makeup">Makeup</option>
										<option value="women-hair-makeup">Hairstyle & Makeup</option>
										<option disabled>---MEN---</option>
										<option value="men-hair">Hairstyle</option>
										<option value="men-face">Makeup Face</option>
										<option value="men-face-chest">Makeup Face & Chest</option>
										<option disabled>---YOUTH---</option>
										<option value="youth-hair">Hairstyle</option>
										<option value="youth-makeup">Makeup</option>
										<option value="youth-hair-makeup">Hairstyle & Makeup</option>
									</select>
								</div>
							</div>
							
							<div class="form-row">
								<div class="form-group col-md-6">
									<label for="event-days" class="highlight">Select Appointment Days</label>
									<select multiple class="form-control contact-input" id="event-days" v-model="form.selectedEventDays" size="7" required>
										<option v-for="day in eventDays" :key="day.date" :value="day.date">{{ day.dayName }}</option>
									</select>
								</div>
								<div class="form-group col-md-6">
									<label for="dance-style" class="highlight">Dance Style (select all that apply)</label>
									<select multiple class="form-control contact-input" id="dance-style" v-model="form.danceStyle" size="8" required>
										<option value="latin">Latin</option>
										<option value="standard">Standard</option>
										<option value="smooth">Smooth</option>
										<option value="rhythm">Rhythm</option>
										<option value="exhibition">Exhibition / Theater Arts</option>
										<option value="night-club">Night Club</option>
										<option value="show-dance">Show Dance</option>
										<option value="Other">Other</option>
									</select>
								</div>
							</div>

							<div class="form-row">
								<div class="form-group col-md-6">
									<div>
										<label class="highlight">Additional Services</label>
									</div>
									<div class="form-check">
										<input class="form-check-input" type="checkbox" id="nextDay" v-model="form.nextDay" value="next-day">
										<label class="form-check-label" for="nextDay">Next Day Touchup</label>
									</div>
									<div class="form-check">
										<input class="form-check-input" type="checkbox" id="rhinestone" v-model="form.rhinestone" value="rhinestone">
										<label class="form-check-label" for="rhinestone">Loose Rhinestone Application</label>
									</div>
									<div class="form-check">
										<input class="form-check-input" type="checkbox" id="eyelash" v-model="form.eyelash" value="eyelash">
										<label class="form-check-label" for="eyelash">Eyelashes Application only</label>
									</div>
                </div>
								<div class="form-group col-md-6">
									<label for="dance-level" class="highlight">Dance Level Category</label>
									<select class="form-control contact-input" id="dance-level" v-model="form.danceLevel" required>
										<option disabled selected>---SELECT---</option>
										<option value="junior">Junior</option>
										<option value="youth">Youth</option>
										<option value="amateur">Amateur</option>
										<option value="pro-am">Pro-Am</option>
										<option value="professional">Professional</option>
									</select>
								</div>

								
							</div>
							
							<div class="form-row">
								<div class="form-group col-md-12">
									<label for="not-available" class="highlight">If Nadezda Vlasova is not available, would  you like to have an appointment with another ENVY Image Studio stylist (Note: choosing “no” doesn’t guarantee an appointment)</label>
									<select class="form-control contact-input" id="not-available" v-model="form.notAvailable" required>
										<option disabled selected>---SELECT---</option>
										<option value="yes">Yes</option>
										<option value="no">No</option>
									</select>
								</div>
							</div>
							
							<div class="form-row">
								<div class="form-group col-md-12">
									<label for="hair-at-night" class="highlight">Are you open to doing your hair the night before? </label>
									<select class="form-control contact-input" id="hair-at-night" v-model="form.hairAtNight" required>
										<option disabled selected>---SELECT---</option>
										<option value="yes">Yes</option>
										<option value="no">No</option>
									</select>
								</div>
							</div>
							
							<h3>Contact Information</h3>
							<div class="form-row">
								<div class="form-group col-md-6">
									<label for="firstName" class="highlight">First Name</label>
									<input type="text" class="form-control contact-input" id="firstName" v-model="form.firstName" required>
								</div>
								<div class="form-group col-md-6">
									<label for="lastName" class="highlight">Last Name</label>
									<input type="text" class="form-control contact-input" id="lastName" v-model="form.lastName" required>
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-md-6">
									<label for="email" class="highlight">Email</label>
									<input type="email" class="form-control contact-input" id="email" v-model="form.email" required>
								</div>
								<div class="form-group col-md-6">
									<label for="phoneNumber" class="highlight">Phone Number</label>
									<input type="text" class="form-control contact-input" id="phoneNumber" v-model="form.phoneNumber" required>
								</div>
							</div>
							<div class="form-group">
								<label for="message" class="highlight">Anything else you would like us to know?</label>
								<textarea class="form-control contact-input" id="message" v-model="form.message" rows="4"></textarea>
							</div>
              <VueHcaptcha
                :sitekey="hCaptchaSiteKey"
                @verify="onCaptchaVerified"
                @expired="onCaptchaExpired"
                @error="onCaptchaError"
              />
							<button type="submit" class="btn btn-warning btn-block" :disabled="!captchaToken">
								Book Appointment <span>&#8594;</span>
							</button>
						</form>
						<p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment';
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
import { fetchUpcomingEvents, formatDate } from '@/utils/events';

export default {
  name: 'SiteBookAnAppointment',
  components: {
    VueHcaptcha
  },
  data() {
    return {
      form: {
        competition: '',
        services: '',
        selectedEventDays: [],
        nextDay: false,
        rhinestone: false,
        eyelash: false,
        danceLevel: '',
        danceStyle: [],
        notAvailable: '',
        hairAtNight: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      },
      formSubmitted: false,
      errorMessage: '',
      captchaToken: '',
      hCaptchaSiteKey: process.env.VUE_APP_HCAPTCHA_SITE_KEY,
      events: [],
      eventDays: [],
    };
  },
  created() {
    this.loadEvents();
  },
  methods: {
    formatDate,
    async loadEvents() {
      this.events = await fetchUpcomingEvents();
    },
    async submitForm() {
      this.errorMessage = '';
      if (
        !this.form.competition || 
        !this.form.services || 
        !this.form.selectedEventDays.length || 
        !this.form.danceLevel || 
        !this.form.danceStyle.length || 
        !this.form.notAvailable || 
        !this.form.hairAtNight || 
        !this.form.firstName || 
        !this.form.lastName || 
        !this.form.email || 
        !this.form.phoneNumber || 
        !this.captchaToken
      ) {
        this.errorMessage = 'Please fill in all required fields.';
        return;
      }

      try {
        const response = await fetch('/api/booking/create', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...this.form, captchaToken: this.captchaToken }),
        });

        if (response.ok) {
          //Send email
          /*const response = await fetch(`${process.env.VUE_APP_SITE_URL}/booking/email`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...this.form, captchaToken: this.captchaToken }),
          });*/
          this.formSubmitted = true;
        } else {
          this.errorMessage = 'Failed to send message. Please try again.';
        }
      } catch (error) {
        this.errorMessage = `Error sending message: ${error.message}`;
      }
    },
    async updateEventDays(eventId) {
      if (!eventId) {
        this.eventDays = [];
        return;
      }

      // Find the selected event from the events array
      const selectedEvent = this.events.find(event => event._id === eventId);

      if (selectedEvent) {
        const startDate = new Date(selectedEvent.eventStartDate);
        const endDate = new Date(selectedEvent.eventEndDate);
        const dateArray = [];

        // Use moment.js for date manipulation
        let currentDate = moment(startDate);
        const lastDate = moment(endDate);

        while (currentDate <= lastDate) {
          dateArray.push({
            date: currentDate.format('YYYY-MM-DD'),
            dayName: currentDate.format('dddd, MMMM Do YYYY'),
          });
          currentDate = currentDate.add(1, 'days');
        }

        this.eventDays = dateArray;
      }
    },
    resetForm() {
      this.form = {
        competition: '',
        services: '',
        nextDay: false,
        rhinestone: false,
        eyelash: false,
        danceLevel: '',
        danceStyle: [],
        notAvailable: '',
        hairAtNight: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: '',
        selectedEventDays: [],
      };
      this.formSubmitted = false;
      this.errorMessage = '';
      this.captchaToken = '';
      this.eventDays = [];
    },
    onCaptchaVerified(token) {
      this.captchaToken = token; // Set the captcha token when verified
    },
    onCaptchaExpired() {
      this.captchaToken = ''; // Clear the captcha token when it expires
    },
    onCaptchaError() {
      this.captchaToken = ''; // Clear the captcha token on error
    },
  },
  watch: {
    'form.competition': function (newVal) {
      this.updateEventDays(newVal);
    },
  },
}
</script>

<style scoped>
.success-message {
  color: #e2b06e;
}

.error-message {
  color: red;
}
</style>
